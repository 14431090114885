<template>
<div>
    <el-carousel height="300px">
        <el-carousel-item>
            <div class="small small-bg-1" style="">
                <div style="color: #ffffff;font-size: 40px;font-weight: bold">星烁网络科技,助力信息化智慧化升级。</div>
            </div>
        </el-carousel-item>
    </el-carousel>
    <el-row>
        <el-col :span="8"><div class="grid-content bg-purple">
            <div class="row" style="align-items: center">
                <div class="grid-red-text">300+</div><div>合作客户</div>
            </div>
            <div class="grid-des">助力北京、西安、山西、内蒙多家政企客户实现信息化建设</div>
        </div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">
            <div class="row" style="align-items: center">
                <div class="grid-red-text">20+</div><div>智慧产品</div>
            </div>
            <div class="grid-des">智慧政企信息化平台、全景云联络中心、泛连接赋能企业全场景智慧营销、数字交易平台</div>
        </div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">
            <div class="row" style="align-items: center">
                <div class="grid-red-text">6+</div><div>全栈式解决方案</div>
            </div>
            <div class="grid-des">Web，Android，iOS，小程序, H5，PC, 全栈解决方案.</div>
        </div></el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row style="margin-top: 40px;" :gutter="0">
        <el-col :span="24"><div style="font-size: 36px;text-align: center;">产品矩阵</div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="col-ss">
            <div class="row">
                <div class="row-content">
                    <div style="font-size: 24px;margin-bottom: 25px;font-weight: 700;">公共服务平台</div>
                    <div class="grid-des">由交易信息展示、业务网上办理、新闻动态、通知公告、交易信息、政策法规、服务指南等模块组成。其中，业务网上办理支持招标采购各主体进行业务相关操作。</div>
                </div>
                <img class="row-img"  src="../assets/service.png" />
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="col-ss">
            <div class="row">
                <div class="row-content">
                    <div style="font-size: 24px;margin-bottom: 25px;font-weight: 700;">全景云联络中心</div>
                    <div class="grid-des">实现企业与客户的沟通的简单与高效，提供全渠道协同、全云化部署、智能化赋能的端到端一站式解决方案，打造最优质的客户体验和营销效果</div>
                </div>
                <img class="row-img"  src="../assets/call.png" />
            </div>
        </el-col>
    </el-row>
    <el-row style="margin-top: 40px;" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="col-ss">
            <div class="row">
                <div class="row-content">
                    <div style="font-size: 24px;margin-bottom: 25px;font-weight: 700;">交易管理平台</div>
                    <div class="grid-des">快速帮助客户实现可伸缩式上线交易订单管理，数字交易应用快速落地，打造易用、业务可扩展性服务平台。</div>
                </div>
                <img class="row-img"  src="../assets/jiaoyi.png" />
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="col-ss">
            <div class="row">
                <div class="row-content">
                    <div style="font-size: 24px;margin-bottom: 25px;font-weight: 700;">无缝障碍辅助接入</div>
                    <div class="grid-des">提供客户无缝式障碍辅助功能接入，借助智能语音合成、色彩转换等工具，帮助障碍人士也能够体验到产品。</div>
                </div>
                <img class="row-img"  src="../assets/fuzhu.png" />
            </div>
        </el-col>
    </el-row>

    <el-divider></el-divider>
    <el-row :gutter="20" style="margin-top: 70px;">
        <el-col :span="24" style="margin-bottom: 70px;"><div style="font-size: 36px;text-align: center;">合作伙伴</div></el-col>
        <el-col :span="6"><img style="width:70px" src="https://www.ti-net.com.cn/assets/images/smartmarketing/cases/aliyun.png" /></el-col>
        <el-col :span="6"><img style="width:70px" src="https://www.ti-net.com.cn/assets/images/smartmarketing/cases/tencent.png" /></el-col>
        <el-col :span="6"><img style="width:70px" src="https://www.ti-net.com.cn/assets/images/smartmarketing/cases/mi.png" /></el-col>
        <el-col :span="6"><img style="width:70px" src="https://img.jiguang.cn/jiguang/20201012/assets/img/new-logo/about_logo_intro_pic_1.svg" /></el-col>
    </el-row>

</div>
</template>

<script>
export default {
name: "Index",
    data(){
        return {
            activeName: 'first',

        }
    },
    methods:{
    }
}
</script>

<style scoped>
.small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}
.small-bg-1 {
    background-image: url('../assets/bannder1.png');
}
.row {
    justify-content: center;
}
.col-ss {
    margin-top: 60px;
    align-items: center;
}
.row-img {
    flex-grow:1;
    max-width: 400px;
}
.row-content {
    padding:12px;
    width: 240px;
    flex-shrink: 0;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-tabs__item.is-active {
    color: #666666 !important;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.el-col {
    border-radius: 4px;
}
.bg-purple-dark {
    background: #99a9bf;
}
.bg-purple {
    /*background: #d3dce6;*/
}

.grid-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding:16px;
    align-items: center;
}
.grid-red-text {
    font-size: 28px;
    color: #cf1920;
    font-weight: 700;
    margin-right: 10px;
}
.grid-des {
    font-size: 14px;
    color: #959595;
    line-height: 30px;
}
.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>
