<template>
  <div class="home">
      <div class="nav">
          <div class="row nav-icon">
              <img style="width: 200px;" src="../assets/logo.png" />
          </div>
          <el-menu router :default-active="activeIndex" text-color="#222222" active-text-color="#2C4895" class="el-menu-ss" mode="horizontal">
              <el-menu-item index="/index">首页</el-menu-item>
              <el-menu-item index="/product">产品 · 方案</el-menu-item>
<!--              <el-menu-item index="3">案例</el-menu-item>-->
              <el-menu-item index="/about">关于我们</el-menu-item>
          </el-menu>
      </div>
      <router-view />
      <div style="display: flex;flex-direction: row;font-size:12px;margin-top: 80px;">
          <p class="copyright"><a target="_blank" href="http://beian.miit.gov.cn/">&copy; 晋ICP备19011850号</a></p>
          <p class="copyright"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14040202000160" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../assets/备案图标.png" style="float:left;"/>晋公网安备 14040202000160号</a></p>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
    data() {
      return {
          activeIndex:"/index"
      }
    }
}
</script>
<style>
.el-menu-item {
    font-weight: bolder;
}
.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    border-bottom: solid 1px #e6e6e6;
    background-color: #ffffff;
    z-index: 999;
}
.nav-icon {
    align-items: center;
    margin-left: 40px;
}
.el-menu-ss {
    margin-right: 20px;
}
.copyright {
    width: 100%;
    margin: 2.5em 0 2em 0;
    font-size: 0.8em;
    text-align: center;
}
a {
    -moz-transition: color 0.2s ease, border-bottom 0.2s ease;
    -webkit-transition: color 0.2s ease, border-bottom 0.2s ease;
    -ms-transition: color 0.2s ease, border-bottom 0.2s ease;
    transition: color 0.2s ease, border-bottom 0.2s ease;
    text-decoration: none;
    border-bottom: dotted 1px;
    color: inherit;
}
</style>
