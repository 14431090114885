<template>
    <div class="product">
        <div style="font-size: 30px;font-weight: 700;margin-top: 70px;padding:70px;">产品及方案</div>
        <el-divider></el-divider>
        <div class="column" style="padding: 24px;">
            <div class="title">政务信息化升级改造</div>
            <div class="line"></div>
            <div class="row" style="margin-top:40px;">
                <div class="item">
                    <div class="item-title">优化工作流程</div>
                    <div class="item-des">通过线上方进一步优化工作流程，将不必要、非到场的流程整个至线上；做到政务工作高效处理。</div>
                </div>
                <div class="item">
                    <div class="item-title">透明化办事流程</div>
                    <div class="item-des">通过微信公众号、App等渠道透明化办事流程，使用信息化手段减少无效等待，办理有目标，审批不重复。</div>
                </div>
                <div class="item">
                    <div class="item-title">无纸化流程</div>
                    <div class="item-des">通过电子化填表信息，引导信息预填写，减少填表错误信息率、重复排队等待。</div>
                </div>
            </div>

        </div>
        <div class="column" style="padding: 24px;margin-top: 20px;">
            <div class="title">cPaaS平台</div>
            <div class="line"></div>
            <div class="row" style="margin-top:40px;">
                <div class="item">
                    <div class="item-title">语音及文本验证码</div>
                    <div class="item-des">为客户提供验证码模块，通过减少无法访问终端客户的情形提高其效率，可随时集成至彼端的PC端及移动APP，或直接部署于基于云的CRM平台。</div>
                </div>
                <div class="item">
                    <div class="item-title">电话通知及文本</div>
                    <div class="item-des">可在若干场景下触发的自动化功能，通过集成该模块，为客户建立的系统会自动拨打电话及发送文本消息，以客户指定的若干内容通知终端客户。</div>
                </div>
                <div class="item">
                    <div class="item-title">频道资源</div>
                    <div class="item-des">提供三种频道资源，即面向企业服务热线的「95xxx」频道、主要面向金融服务公司的 DID（直接拨入）频道及面向隐号模块及营销活动的频道。</div>
                </div>
            </div>

        </div>
        <div class="column" style="padding: 24px;margin-top: 20px;">
            <div class="title">AI语音合成</div>
            <div class="line"></div>
            <div class="row" style="margin-top:40px;">
                <div class="item">
                    <div class="item-title">文本语音转换</div>
                    <div class="item-des">通过深度学习，针对语音特征和语音信号的关系进行建模。合成更接近自然发音的语音。</div>
                </div>
                <div class="item">
                    <div class="item-title">可定制发音</div>
                    <div class="item-des">通过机器学习，利用少量样本，自定义发音人音色，定制化语音发音音色。</div>
                </div>
                <div class="item">
                    <div class="item-title">服务化</div>
                    <div class="item-des">部署方式灵活，训练模型通过云服务将语音合成服务化，也可以私有离线部署。</div>
                </div>
            </div>

        </div>
        <div class="column" style="padding: 24px;margin-top: 20px;">
            <div class="title">订单交易系统</div>
            <div class="line"></div>
            <div class="row" style="margin-top:40px;justify-content: space-around;">
                <div class="item">
                    <div class="item-title">接口可拓展</div>
                    <div class="item-des">通过订单交易系统+移动端Api可快落地商城订单系统</div>
                </div>
                <div class="item">
                    <div class="item-title">服务已拓展</div>
                    <div class="item-des">通过后台管理可快速横向扩展不同业务类型。</div>
                </div>
                <div class="item">
                    <div class="item-title">可视化</div>
                    <div class="item-des">对重要信息实现可视化面板，快速查看、跟踪业务变化。</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
name: "Product"
}
</script>

<style scoped>
.line {
    height: 2px;
    width: 200px;
    background-color: #dddddd;
    background-image: -moz-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -webkit-linear-gradient(
        90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -ms-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: linear-gradient(
        90deg, #efa8b0, #a89cc8, #8cc9f0);
}
.item {
    padding:24px;
    align-items: center;
}
.title {
    text-align: left;
    font-size: 22px;
    color: #333333;
    font-weight: bolder;
    margin-bottom: 24px;
}
.item-title {
    font-size: 20px;
    color: #333333;
    font-weight: bolder;
}
.item-des {
    margin-top: 20px;
    font-size: 16px;
    color: #666666;
    line-height: 24px;
}
.product {
    /*padding-top: 70px;*/
}
</style>
