import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Product from '../views/Product.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: 'index',
        component: Index
      },
      {
        path: 'product',
        component: Product
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      }
    ]
  },

]

const router = new VueRouter({
  routes
})

export default router
